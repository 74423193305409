import React, { useEffect, useState } from "react"
import { avax, bnb, cro, eth, ftm, matic } from "../../images/chains"
import { networkData } from "../../common/networks"
import { payableValues } from "../../common/deployer"

const FeeSelector = () => {
  const chainIds = Object.keys(networkData.mainnet)
  chainIds.sort((a, b) => a - b)
  const networks = chainIds.map(chainId => ({
    chainId,
    name: networkData.mainnet[chainId].wallet.nativeCurrency.name,
    icon: networkData.mainnet[chainId].wallet.nativeCurrency.image,
    symbol: networkData.mainnet[chainId].wallet.nativeCurrency.symbol,
  }))
  const getPrice = chainId =>
    payableValues[chainId]?.slice(0, payableValues[chainId].length - 15) / 1000
  const [network, setNetwork] = useState(0)
  const [delay, setDelay] = useState(2000)
  useEffect(() => {
    const timeout = setTimeout(() => {
      setNetwork((network + 1) % networks.length)
      setDelay(2000)
    }, delay)
    return () => clearTimeout(timeout)
  }, [network, delay])

  return (
    <section className="mx-auto flex w-full max-w-3xl flex-col items-center justify-between gap-4 p-16">
      <h2 className="text-xl">Not on testnet?</h2>
      <div className="flex w-full flex-col-reverse justify-between gap-6 sm:flex-row">
        {/** Selector */}
        <div className="flex flex-col items-center justify-center gap-2">
          <span>Select your blockchain</span>
          <div className="grid w-max max-w-xs grid-cols-3 gap-4">
            {networks.map(({ name, icon, symbol, chainId }, i) => (
              <button
                onClick={() => {
                  setNetwork(i)
                  setDelay(4000)
                }}
              >
                <img
                  src={icon}
                  alt={name}
                  title={name}
                  className={`transform-gpu duration-500 ${
                    chainId === networks[network].chainId
                      ? "w-16"
                      : "w-12 opacity-50"
                  }`}
                />
              </button>
            ))}
          </div>
        </div>
        {/** Fee visualizer */}
        <div className="flex flex-col items-center justify-center gap-4">
          <span>Deployment fee</span>
          <span className="card-background flex w-56 items-center justify-center rounded-lg py-8 text-center text-4xl">
            {getPrice(networks[network].chainId)} {networks[network].symbol}
          </span>
          <span>
            Want to know more?{" "}
            <a
              className="text-sky underline"
              href="//docs.tsubakilabs.com/solutions/eva-deployer/pricing"
              target="_blank"
              rel="noreferrer"
            >
              See our pricing page
            </a>
          </span>
        </div>
      </div>
    </section>
  )
}

export default FeeSelector
