import React from "react"

const Label = ({ color, children }) => {
  return (
    <span
      className={`flex w-max items-center rounded-full py-1 px-2 ${
        color || "bg-slate-200"
      }`}
    >
      {children}
    </span>
  )
}

export default Label
