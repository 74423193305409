import React from "react"

const Banner = ({ message }) => {
  return (
    <section className="flex w-full items-center justify-center bg-primary p-8">
      <span className="max-w-5xl text-3xl text-white">{message}</span>
    </section>
  )
}

export default Banner
