import React from "react"
import Button from "../common/utils/actionButton"
import Card from "../common/utils/card"

const Commission = () => {
  return (
    <section className="flex w-full flex-col items-center justify-center gap-8 px-4 py-16">
      <div className="grid w-full max-w-sm grid-cols-1 gap-8 sm:max-w-3xl sm:grid-cols-2">
        {/** Not taxable */}
        <div className="flex flex-col items-center gap-4">
          <h2 className="font-bold">Non-taxable token</h2>
          <Card background="card-background">
            <div className="flex h-56 transform-gpu flex-col items-center justify-center gap-4 py-6 px-8 duration-500 md:h-44">
              <span>We will charge no commission!</span>
              <span>One-time invesment only!</span>
              <Button link="deployer">Try it FOR FREE!</Button>
            </div>
          </Card>
        </div>
        {/** Taxable */}
        <div className="flex flex-col items-center gap-4">
          <h2 className="font-bold">Taxable token</h2>
          <Card background="card-background">
            <div className="flex h-56 transform-gpu flex-col items-center justify-center gap-4 py-6 px-8 duration-500 md:h-44">
              <span>We only get 20% of the tax you charge.</span>
              <span>The remaining 80% will go entirely to you.</span>
              <Button link="deployer">Create your token now!</Button>
            </div>
          </Card>
        </div>
      </div>
      <h2>
        Need a different option?{" "}
        <a
          className="text-sky underline"
          href="//docs.tsubakilabs.com/solutions/tailored-solutions"
          target="_blank"
          rel="noreferrer"
        >
          See our tailored solutions
        </a>
      </h2>
    </section>
  )
}

export default Commission
