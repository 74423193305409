import React from "react"
import { graphql } from "gatsby"
import Layout from "../../../components/common/body/layout"
import CallToAction from "../../../components/eva-product/callToAction"
import { HeadHelmet } from "../../../components/common/body/seo"
import Steps from "../../../components/eva-product/steps"
import Banner from "../../../components/common/body/banner"
import FeeSelector from "../../../components/eva-product/fees"
import Commission from "../../../components/eva-product/commissions"

const Eva = ({ data: { globals, solution } }) => {
  // TODO: check prop data
  return (
    <Layout
      seo={{
        description: globals.description,
      }}
      lang={globals.node_locale}
      title={`${globals.title} - ${solution.name}`}
      site={globals.title}
      url={`/solutions/${solution.slug}`}
      preview={solution.twitterImage.localFile.publicURL}
      solutions={globals.solutionList}
      contactLink={globals.contactLink}
      strings={globals.strings}
      solutionsHeader={globals.solutionsHeader}
    >
      <CallToAction />
      <Steps />
      <Banner message="Try it for free on testnet" />
      <FeeSelector />
      <Banner message="Who pays commission?" />
      <Commission />
    </Layout>
  )
}

export const Head = ({ location, data: { globals, solution } }) => {
  return (
    <HeadHelmet
      seo={{
        description: solution.excerpt,
      }}
      lang={globals.node_locale}
      title={`${globals.title} - ${solution.name}`}
      site={globals.title}
      location={location.pathname}
      preview={solution.twitterImage.localFile.publicURL}
    />
  )
}

export default Eva

export const query = graphql`
  {
    globals: contentfulPage {
      title
      description
      solutionsHeader
      solutionList {
        name
        slug
        released
        available
        shown
        node_locale
      }
      contactLink {
        label
        link
      }
      strings {
        goHome
        close
        copyright
        openMenu
        about {
          link
          label
        }
      }
      node_locale
    }
    solution: contentfulSolutions(
      id: { eq: "5e141f7a-4304-594c-8c1a-a3f5eafbf716" }
    ) {
      name
      slug
      excerpt
      strings {
        id
        connection {
          description
          popular
          title
        }
        creation {
          action
          confirmation
        }
        customization {
          taxes {
            error
            placeholder
            tip
          }
          title
          toggles {
            label
            tip
          }
        }
        errors {
          creation
          required
        }
        information {
          fields {
            error
            placeholder
            tip
          }
          title
        }
        networkOptions {
          exchange {
            placeholder
            tip
          }
          network {
            placeholder
            tip
            testnet {
              label
              tip
            }
          }
          title
        }
        terms {
          label
          link
        }
      }
      twitterImage {
        localFile {
          publicURL
        }
      }
    }
  }
`
