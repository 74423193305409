import React from "react"
import Label from "../common/utils/label"
import rocket from "../../images/elements/rocket2.svg"
import Button, { InvertedButton } from "../common/utils/actionButton"

const CallToAction = () => {
  return (
    <section className="flex w-full items-center justify-center bg-[#22334b]">
      <div className="flex w-full max-w-5xl flex-col-reverse items-center justify-center gap-8 px-8 py-10 text-white sm:flex-row">
        {/** Call to action */}
        <div className="sm:max-w-2/3 flex w-full flex-col gap-2 sm:w-max">
          <Label color={"bg-white"}>
            <span className="translate-y-px text-sm text-gray-700">
              Token generator
            </span>
          </Label>
          <h1 className="w-full text-4xl">Eva deployer</h1>
          <p className="text-lg">
            Easily deploy ERC-20 tokens on the blockchain!
          </p>
          <ul className="list-inside pl-4 text-lg list-check">
            <li>No coding requirements</li>
            <li>Just a few clicks</li>
            <li>Ultra customizable</li>
          </ul>
          <InvertedButton link="deployer">
            <span className="text-gray-700">Try now!</span>
          </InvertedButton>
        </div>
        {/** Descriptive image */}
        <div className="aspect-square w-72 max-w-full transform-gpu duration-500 sm:w-1/3">
          <img src={rocket} />
        </div>
      </div>
    </section>
  )
}
export default CallToAction
