import React from "react"
import Card from "../common/utils/card"
import step1 from "../../images/elements/deploy1.svg"
import step2 from "../../images/elements/deploy2.svg"
import step3 from "../../images/elements/deploy3.svg"

const Steps = () => {
  const steps = [
    {
      title: "Select your options",
      icon: step1,
    },
    {
      title: "Deploy with one click!",
      icon: step2,
    },
    {
      title: "Manage your new token",
      icon: step3,
    },
  ]
  return (
    <section className="flex w-full items-center justify-center">
      <div className="flex w-full max-w-5xl flex-col items-center justify-center gap-8 px-8 py-24 md:flex-row">
        <span className="w-full text-center md:w-auto">
          Just 3 simple steps
        </span>
        <div className="flex flex-row gap-14 sm:flex-col sm:gap-8">
          <div className="w-0 py-12 sm:h-0 sm:w-full sm:py-0 sm:px-20">
            <div className="flex h-full w-full flex-col items-center justify-between border-x border-dashed border-gray-100 sm:flex-row sm:border-x-0 sm:border-y">
              {steps.map((_, i) => (
                <div className="aspect-square w-6 rounded-full bg-primary text-center text-xs leading-6 text-white">
                  {i + 1}
                </div>
              ))}
            </div>
          </div>

          <ol className="flex flex-col justify-center gap-8 sm:flex-row">
            {steps.map(({ title, icon }) => (
              <li>
                <Card background="card-background">
                  <div className="max-w-1/3 flex h-24 transform-gpu flex-col justify-center gap-2 p-4 text-black duration-500 sm:h-28 sm:w-40 sm:justify-between sm:gap-0">
                    <div className="flex w-full justify-center">
                      <img src={icon} className="w-6" />
                    </div>
                    <div className="w-full text-center">{title}</div>
                  </div>
                </Card>
              </li>
            ))}
          </ol>
        </div>
      </div>
    </section>
  )
}

export default Steps
